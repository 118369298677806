import contact from './lib/contact'
import rebox from './lib/rebox'
import { toggleBelowFold, scrollSpy, smoothScroll } from './lib/scroll'

$(document).ready(function () {
  contact('#contact-form')
  rebox('.instanode-headstart-thumbimage a')
  toggleBelowFold()
  // scrollSpy('.header-nav-sub:visible', {offset: 0})
  // smoothScroll('a[href^="#"]', {offset: 0})
  
  $('.burger-menu').click(function () {
    $(this).toggleClass('open')
  })
  
  $('#menu li a').click(function () {
    $('.burger-menu').removeClass('open')
  })
  

  if ($(window).width() <= 991) {
    $('.gallery-item').click(function () {
      $(this).toggleClass('show')
    })
  } else {
    $('.gallery-item').hover(function () {
      $(this).toggleClass('show')
    })
  };
})

$('.carousel.teaser-slider .neos-contentcollection').slick({
  infinite: false,
  dots: false,
  arrows: true,
  speed: 500,
  cssEase: 'linear',
  ease: 'ease-in',
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: false,
  centerMode: false,
  touchMove: false,
  infinite: true,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        touchMove: true
      }
    },
    {
      breakpoint: 990,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        infinite: true,
        speed: 500
      }
    }
  ]
})

$('#go-back').on('click', function () {
  history.back();
});